import React, { useState, useContext, useRef, useEffect,useCallback} from "react";
import { AppContext } from "../Contexts/AppContextProvider";
import { HTTPResponse, ToastMessageType } from "../Helpers/Enums";
import { UserLoginService } from "../WebApiServices/UserLogin.service";
import { UserLoginMessage } from "../Helpers/HelperText";
import { useNavigate } from "react-router-dom";
import { AddLocalStorageData, IsEmailValid } from "../Helpers/Utilities";
import { Config } from "../Helpers/Config";
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";
import { RecaptchaService } from "../WebApiServices/Recaptcha.service";


export const Login = () => {
    const { ToggleLoader, ShowToast, ShowActionPopUp } = useContext(AppContext);
    const navigate = useNavigate();
    const [creds, setCreds] = useState({
        email: "",
        password: ""
    })
    const [showPassword, setShowPassword] = useState(false);
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [credsError, setCredsError] = useState("");

    //const [selectCaptcha, setSelectCaptcha] = useState(false);
    const [captchaError, setCaptchaError] = useState(false);
    const [token, setToken] = useState("");
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
    const [isTokenVerified, setIsTokenVerified] = useState(false);

    const recaptchaRef = useRef(null);

      // This function handles key presses and submits the form when "Enter" is pressed.
  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      await OnLoginButtonClicked(event);
    }
  };

  useEffect(() => {
    // Add event listener for the 'Enter' key when the component mounts
    document.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [creds]);


    const OnLoginButtonClicked = async (e) => {
        //ShowActionPopUpModal();
        e.preventDefault();  // Prevent default form submission behavior

        if (await Validate()) {
            if (!token) {
                setCaptchaError(UserLoginMessage.reCAPTCHAFail); 
                return;
        }
        ToggleLoader(true);
        await LoginUser();
        ToggleLoader(false);
        }
    };

    const Validate = async () => {
        let isValid = true;
        // Validate email
        if (!creds.email) {
            isValid = false;
            setEmailError(UserLoginMessage.LoginEmailBlank);
        } else if (!IsEmailValid(creds.email)) {
            setEmailError(UserLoginMessage.LoginInvalidEmail);
            isValid = false;
        } else {
            setEmailError("");
        }

        // Validate password
        if (!creds.password) {
            isValid = false;
            setPasswordError(UserLoginMessage.LoginPasswordBlank);
        } else {
            setPasswordError("");
        }

        // if (!isValid) {
        //     ResetCaptcha();
        // }

        return isValid;
    };
    const LoginUser = async () => {
        try {

            // Verify reCAPTCHA with the backend
                const recaptchaResponse = await RecaptchaService.Verify({
                    token
                });


                if (recaptchaResponse.data.success) {
                    // Authenticate user once reCAPTCHA verification succeeds
                    //ToggleLoader(true);
                    const response = await UserLoginService.UserLogin({
                        email: creds.email,
                        password: creds.password,
                    });

            //ToggleLoader(false);
            if (response.status !== HTTPResponse.OK) {
                setCredsError(UserLoginMessage.LogginFailure);
                setRefreshReCaptcha(!refreshReCaptcha);
            }

            else {
                //AddLocalStorageData("centerId", response?.data?.centerId ?? 0);
                AddLocalStorageData("userId", response?.data?.abstractorId ?? 0);
                // AddLocalStorageData("userRole", response?.data?.roleId ?? 0);
                //AddLocalStorageData("centerName", response?.data?.centerName ?? 0);
                //AddLocalStorageData("empName", response?.data?.employeeName ?? 0);

                const userArr = {
                    userId: response?.data?.abstractorId ?? 0,
                    userRole: response?.data?.roleId ?? 0,
                    empName: response?.data?.employeeName ?? "",
                    centerId: response?.data?.centerId ?? 0,
                    centerName: response?.data?.centerName ?? "",
                };
                AddLocalStorageData("user", JSON.stringify(userArr))
                return navigate("/ManagePatients");
            }
            
        } else{
            setCaptchaError(UserLoginMessage.reCAPTCHAFail); 
            setRefreshReCaptcha(!refreshReCaptcha);
        }
        
     } catch (error) {
            setCredsError(UserLoginMessage.LogginFailure);
            setRefreshReCaptcha(!refreshReCaptcha);
        } 
    };


    const OnEmailChange = (e) => {
        setCreds(prevState => ({ ...prevState, email: e.target.value }))
        setEmailError("");
        setCredsError("");
    }
    const OnPasswordChange = (e) => {
        setCreds(prevState => ({ ...prevState, password: e.target.value }))
        setPasswordError("");
        setCredsError("");
    }
    const OnForgorPasswordClicked = () => {
        navigate("/PasswordRecovery")
    }



    const setTokenFunc = useCallback((getToken) => {
        if (!token) {// Prevent token reset if token is already set
          setToken(getToken);
          setIsTokenVerified(true);
          setCaptchaError("");
          }
      },[]);


    /* <summary>
    date: 06-04-2024
    Name: AP
    description: Captcha event handler
    <summary>*/ 
    const OnCaptchaSelect = () => {
        //setSelectCaptcha(true);
        setCaptchaError("");
    };

    // const ShowActionPopUpModal = async () => {
    //   const choice = await ShowActionPopUp(
    //     "Alert", //title
    //     "Do you want to login", //message
    //     "Yes" //Confirm button text
    //   );

    //   if (!choice) {
    //     return;
    //   } else {
    //     ToggleLoader(true);
    //     const response = await UserLoginService.UserLogin({
    //       userName: creds.email,
    //       password: creds.password,
    //     });
    //     ToggleLoader(false);
    //     if (response.status == HTTPResponse.OK) {
    //       ShowToast(UserLoginMessage.LogginSuccess, ToastMessageType.Success);
    //       AddLocalStorageData("userId", response.data.userId);
    //       navigate("/Dashboard");
    //     } else {
    //       ShowToast(UserLoginMessage.LogginFailure, ToastMessageType.Failed);
    //     }
    //   }
    // };

    return (
        <div className="gradient-custom admin-login">
            <div className="container py-5 custom-container">
                <div className="row justify-content-center align-items-center h-100">
                    <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                        <div
                            className="card bg-white text-black"
                            style={{ borderRadius: "1rem" }}
                        >
                            <div className="card-body p-4">
                                <div className="mb-md-2 mt-md-2 pb-2">
                                    <h2 className="fw-bold mb-4 text-uppercase text-center login-heading">
                                        Login
                                    </h2>
                                    <div className="form-outline form-white mb-4">
                                        <input
                                            type="email"
                                            id="typeEmailX"
                                            placeholder="Email"
                                            className="form-control form-control-lg placeholder-font-size"
                                            value={creds.email}
                                            onChange={OnEmailChange}
                                            onKeyDown={handleKeyDown}  // Added onKeyDown for handling Enter key
                                        />
                                        {emailError && (
                                            <div className="invalid-message">
                                                <i
                                                    className="fa fa-exclamation-circle"
                                                    aria-hidden="true"
                                                ></i>
                                                <div className="invalid-login-input">
                                                    {emailError}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="form-outline form-white mb-1">
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            className="form-control"
                                            name="password."
                                            required
                                            id="password-id"
                                            placeholder="Password"
                                            value={creds.password}
                                            onChange={OnPasswordChange}
                                            onKeyDown={handleKeyDown}  // Added onKeyDown for handling Enter key
                                        />
                                        <div
                                            className="toggle-password"
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            {showPassword ? (
                                                <i className="fa fa-eye" id="togglePassword"></i>
                                            ) : (
                                                <i className="fa fa-eye fa-eye-slash"></i>
                                            )}
                                        </div>
                                        {passwordError && (
                                            <div className="invalid-message">
                                                <i
                                                    className="fa fa-exclamation-circle"
                                                    aria-hidden="true"
                                                ></i>
                                                <div className="invalid-login-input">
                                                    {passwordError}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="forgot-password-hyperlink">
                                        <a onClick={OnForgorPasswordClicked}>Forgot Password</a>
                                    </div>


                                    {/* CAPTCHA */}
                                    <div className="text-center mt-2 mb-2">
                                        
                                            <GoogleReCaptchaProvider reCaptchaKey={Config.reCAPTCHASiteKey}>
                                            <GoogleReCaptcha
                                                className="google-recaptcha-custom-class"
                                                onVerify={setTokenFunc}  // Trigger token set only once
                                                refreshReCaptcha={refreshReCaptcha}
                                                />
                                                    </GoogleReCaptchaProvider>
                                                {captchaError && (
                                                    <div
                                                        className="invalid-login-input"
                                                        style={{ marginTop: 0 }}
                                                    >
                                                        {captchaError}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="text-center mt-2 mb-2">
                                            <center>
                                            <button 
                                                className="btn btn-outline-light btn-lg px-5 login-button"
                                                onClick={OnLoginButtonClicked}
                                                disabled={!isTokenVerified}  // Disable login button until token is verified

                                            >
                                                Login
                                            </button>
                                        </center>
                                    </div>

                                    {/* <div className="text-center mt-2 mb-2">
                    <center>
                      <button
                        disabled={!creds.email || !creds.password}
                        className="btn btn-outline-light btn-lg px-5 login-button"
                        type="submit"
                        onClick={OnLoginButtonClicked}
                      >
                        Login
                      </button>{" "}
                    </center>
                  </div> */}
                                    <br />
                                    {credsError && (
                                        <div className="invalid-message">
                                            <div className="invalid-login-input">
                                                <center>
                                                    {credsError}
                                                </center>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Login;
