import React, { useContext, useEffect, useState } from "react";
import SideBarMenu from "../../../Components/SideBarMenu";
import {
  PageDesignConstants,
  SideMenuItems,
  ToastMessageType,
  ToastMessages,
} from "../../../Helpers/Enums";
import SaveAndNavigate from "../../../Components/SaveAndNavigate";
import PatientDetails from "../../../Components/PatientInformation";
import ErrorField from "../../../Components/ErrorField";
import {
  CarTCellTherapy_Msgs,
  CommonError_Msgs,
  CommonMsgs,
} from "../../../Helpers/HelperText";
import { AppContext } from "../../../Contexts/AppContextProvider";
import { TreatmentResponseService } from "../../../WebApiServices/TreatmentResponse.service";
import {
  GetLocalStorageData,
  GetLoggedInUserID,
} from "../../../Helpers/Utilities";
import { HttpStatusCode } from "axios";

const CarTCellTherapy = () => {
  const { ShowToast, ToggleLoader, ShowActionPopUp, HandleSessionTimeout, appState } =
    useContext(AppContext);
    const { tmtAfterEpcoLOTCnt } = appState ?? 0;

  const navigatePaths = {
    prevPage: tmtAfterEpcoLOTCnt == 0 ? "/Epcoritamab" : `/TreatmentsAfterEpcoritamab/${tmtAfterEpcoLOTCnt}`,
    currPage: "/CARTCellTherapy",
    nextPage: "/HRU",
  };
  const patientId = GetLocalStorageData("patientId") ?? 0;
  const userId = GetLoggedInUserID();

  const InitialSoftErrList = {
    q92Unk: false,
    q93Unk_err: false,
  };

  const InitialHardErrList = {
    q92Empty: false,
    q93Empty: false,
    q93EmptyOtherTxt: false,
  };

  const [softErrList, setSoftErrList] = useState(InitialSoftErrList);
  const [hardErrList, setHardErrList] = useState(InitialHardErrList);
  const [showTopErrMsg, setShowTopErrMsg] = useState(false);
  const [chkData, setChkData] = useState(0);

  const [carTCellDetails, setCarTCellDetails] = useState({
    q92CarTCell: null,
    q93FinBurden: false,
    q93Insurance: false,
    q93Travel: false,
    q93Toxicity: false,
    q93Preference: false,
    q93Manufacture: false,
    q93Other: false,
    q93_other_txt: "",
    q93Unk: null,
  });

  const ValidateForm = () => {
    let isValid = true;
    // Validation for q92
    if (carTCellDetails.q92CarTCell === null) {
      setHardErrList((prev) => ({
        ...prev,
        q92Empty: true,
      }));
      isValid = false;
    } else {
      setHardErrList((prev) => ({
        ...prev,
        q92Empty: false,
      }));
    }

    // Validation for q93 (if q92 is answered)
    if (carTCellDetails.q92CarTCell === 1) {
      const {
        q93FinBurden,
        q93Insurance,
        q93Travel,
        q93Toxicity,
        q93Preference,
        q93Manufacture,
        q93Other,
        q93Unk,
        q93_other_txt,
      } = carTCellDetails;

      if (
        !q93FinBurden &&
        !q93Insurance &&
        !q93Travel &&
        !q93Toxicity &&
        !q93Preference &&
        !q93Manufacture &&
        !q93Other &&
        !q93Unk
      ) {
        setHardErrList((prev) => ({
          ...prev,
          q93Empty: true,
        }));
        isValid = false;
      } else if (q93Other && q93_other_txt === "") {
        setHardErrList((prev) => ({
          ...prev,
          q93EmptyOtherTxt: true,
        }));
        isValid = false;
      } else {
        setHardErrList((prev) => ({
          ...prev,
          q93Empty: false,
        }));
      }
    }

    return isValid;
  };

  const LoadData = async () => {
    try {
      if (patientId > 0) {
        
        const response =
          await TreatmentResponseService.GetAllCarTCellTherapyData(patientId);

          console.log(response);
        if (response?.status === HttpStatusCode.Ok) {
          const { data } = response;
          setCarTCellDetails({
            q92CarTCell: data.receiveCarTCellTherapy,
            q93FinBurden: data.carTCellInaccessibilityCauseFinancialburden,
            q93Insurance: data.carTCellInaccessibilityCauseInsurance,
            q93Travel: data.carTCellInaccessibilityCauseTravel,
            q93Toxicity: data.carTCellInaccessibilityCauseToxicity,
            q93Preference: data.carTCellInaccessibilityCausePatientpreference,
            q93Manufacture: data.carTCellInaccessibilityCauseManufacturing,
            q93Other: data.carTCellInaccessibilityCauseOth,
            q93_other_txt: data.carTCellInaccessibilityCauseOthTxt,
            q93Unk: data.carTCellInaccessibilityCauseUnk?99:null,
          });
        } else if (response?.status === HttpStatusCode.Unauthorized) {
          HandleSessionTimeout();
        }
      }
    } catch (error) {
      ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
    }
  };

  useEffect(() => {
    async function FetchData() {
      if (!isNaN(patientId) && Number(patientId) !== 0) {
        ToggleLoader(true);
        await LoadData();
        ToggleLoader(false);
      }
    }
    FetchData();
  }, []);

  const SavePage = async (validate) => {
    try {
      ToggleLoader(true);

      //remove soft errors on click of Save
      setSoftErrList(InitialSoftErrList);
      setHardErrList(InitialHardErrList);

      // // Validate form
      // if (!ValidateForm()) {
      //   setShowTopErrMsg(true); // Show top error message
      //   ToggleLoader(false); // Stop loader
      //   return false;
      // }
      // setShowTopErrMsg(false); // Hide top error message
      let isValid = validate ? ValidateForm() : true;
      if (isValid) {
        setShowTopErrMsg(false)
      const carTCellTherapyModel = {
        Patientid: patientId,
        UserId: userId,
        isValidated: validate,
        
        ReceiveCarTCellTherapy: carTCellDetails.q92CarTCell,
        CarTCellInaccessibilityCauseFinancialburden:
          carTCellDetails.q93FinBurden,
        CarTCellInaccessibilityCauseInsurance: carTCellDetails.q93Insurance,
        CarTCellInaccessibilityCauseTravel: carTCellDetails.q93Travel,
        CarTCellInaccessibilityCauseToxicity: carTCellDetails.q93Toxicity,
        CarTCellInaccessibilityCausePatientpreference:
          carTCellDetails.q93Preference,
        CarTCellInaccessibilityCauseManufacturing:
          carTCellDetails.q93Manufacture,
        CarTCellInaccessibilityCauseOth: carTCellDetails.q93Other,
        CarTCellInaccessibilityCauseOthTxt: carTCellDetails.q93_other_txt,
        CarTCellInaccessibilityCauseUnk: carTCellDetails.q93Unk,
      };

      

      if (patientId > 0) {
        const response = await TreatmentResponseService.SaveCarTCellTherapy(
          carTCellTherapyModel
        );
        ToggleLoader(false);
        if (response.status !== HttpStatusCode.Ok) {
          if (response?.status === HttpStatusCode.Unauthorized) {
            HandleSessionTimeout();
          } else throw response.error;
        } else{
          await LoadData();
          ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
        }
      }
      return true;
    }
    else{
      ToggleLoader(false);
      setShowTopErrMsg(true)
      return false;
    }
    } catch (error) {
      ToggleLoader(false);
      ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed);
    }
  };

  const HandleFieldChange = (e) => {
    const { name, id, value, checked } = e.target;
    
    switch (name) {
      case "q92CarTCell": {
        setCarTCellDetails((prev) => ({
          ...prev,
          [name]: parseInt(value), //value,
          q93FinBurden: "",
          q93Insurance: "",
          q93Travel: "",
          q93Toxicity: "",
          q93Preference: "",
          q93Manufacture: "",
          q93Other: "",
          q93_other_txt: "",
          q93Unk: false,
        }));

        setSoftErrList(InitialSoftErrList);
        setHardErrList(InitialHardErrList);

        setSoftErrList((prev) => ({
          ...prev,
          q92Unk: parseInt(value) === 99,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q92Empty: false,
        }));

        break;
      }

      case "q93FinBurden":
      case "q93Insurance":
      case "q93Travel":
      case "q93Toxicity":
      case "q93Preference":
      case "q93Manufacture":
      case "q93Other": {
        setCarTCellDetails((prev) => ({
          ...prev,
          [name]: !prev[name],
          setSoftErrList: false,
          q93Unk: false,
        }));

        setSoftErrList((prev) => ({
          ...prev,
          q93Unk_err: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q93Empty: false,
          q93EmptyOtherTxt: false,
          // q93EmptyOtherTxt:
          //   name === "q93Other" && !checked ? false : prev.q93EmptyOtherTxt, // Reset q93EmptyOtherTxt if "Other" is unchecked

        }));
        break;
      }

      case "q93_other_txt": {
        setCarTCellDetails((prev) => ({
          ...prev,
          [name]: value,
          q93Other: true,
          q93Unk: false,
        }));

        setSoftErrList((prev) => ({
          ...prev,
          q93Unk_err: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q93Empty: false,
          q93EmptyOtherTxt: false,
        }));

        break;
      }

      case "q93Unk": {
        setCarTCellDetails((prev) => ({
          ...prev,
          [name]: Number(value),
          q93FinBurden: false,
          q93Insurance: false,
          q93Travel: false,
          q93Toxicity: false,
          q93Preference: false,
          q93Manufacture: false,
          q93Other: false,
          q93_other_txt: "",
        }));

        setSoftErrList((prev) => ({
          ...prev,
          q93Unk_err: Number(value) === 99,
        }));


        setHardErrList((prev) => ({
          ...prev,
          q93Empty: false,
          q93EmptyOtherTxt: false,
        }));


        break;
      }

      default:
        break;
    }
    // Hide top error message after an option is selected
    setShowTopErrMsg(false);
  };
  return (
    <>
      <section className="survey-patient-details-section">
        <div className="row">
          <PatientDetails />
          <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
            <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
          </div>
        </div>
      </section>

      <section className="main-survey-section short-page">
        <div className="row">
          <SideBarMenu activePageId={SideMenuItems.CarTCellTherapy} />
          <div className="col-md-9 col-pad-left">
            <div className="survey-section-content section-min-height">
              <div className="survey-section-content-header">
                <h4>Intention to Treat with CAR T Cell Therapy </h4>
              </div>
              {showTopErrMsg && (
                <div className="clean-error ">
                  <ErrorField
                    show={showTopErrMsg}
                    message={CommonError_Msgs.Hard_Empty}
                  />
                </div>
              )}
              <div className="survey-question-section section-min-height">
                <div className="survey-question-content">
                  <div style={PageDesignConstants.PageDescriptionStyle}>
                    <i>
                      In this section, you will be asked to provide information
                      about intention to treat with CAR T cell therapy.
                    </i>
                  </div>
                  <div className="question-answer">
                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>Q115.</span>
                        <span className="quest-text-pad">
                          At any point during the patient’s course of treatment,
                          was the patient planning to receive CAR T cell therapy
                          but was unable to receive the infusion?
                        </span>
                      </div>
                      <div className="double-dig-answer">
                        <div className="option-without-input">
                          <input
                            type="radio"
                            id="q92Yes"
                            name="q92CarTCell"
                            value={1}
                            checked={carTCellDetails.q92CarTCell === 1}
                            onChange={HandleFieldChange}
                          />
                          <label htmlFor="q92Yes">
                            <span className="radio-text-padding">Yes</span>
                          </label>
                        </div>
                        <div className="option-without-input">
                          <input
                            type="radio"
                            id="q92No"
                            name="q92CarTCell"
                            value={0}
                            checked={carTCellDetails.q92CarTCell === 0}
                            onChange={HandleFieldChange}
                          />
                          <label htmlFor="q92No">
                            <span className="radio-text-padding">No</span>
                          </label>
                        </div>
                        <div className="option-without-input">
                          <input
                            type="radio"
                            id="q92Unk"
                            name="q92CarTCell"
                            value={99}
                            checked={carTCellDetails.q92CarTCell === 99}
                            onChange={HandleFieldChange}
                          />

                          <label htmlFor="q92Unk">
                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                      </div>
                      <ErrorField
                        show={hardErrList.q92Empty}
                        message={CarTCellTherapy_Msgs.Q92}
                      />
                      <ErrorField
                        show={softErrList.q92Unk}
                        message={CommonMsgs.Unknown}
                      />
                    </div>
                    {carTCellDetails.q92CarTCell === 1 && (
                      <div className="question-bot-sapce">
                        <div className="question question-weight">
                          <span>Q116.</span>
                          <span className="quest-text-pad">
                            Why was the patient ultimately unable to receive CAR
                            T cell therapy?
                          </span>
                        </div>
                        <div className=" double-dig-answer">
                          <div className="">
                            <input
                              type="checkbox"
                              id="q93FinBurden"
                              name="q93FinBurden"
                              checked={carTCellDetails.q93FinBurden}
                              onChange={HandleFieldChange}
                            />
                            <label htmlFor="q93FinBurden">
                              <span className="radio-text-padding">
                                Financial burden/too expensive
                              </span>
                            </label>
                          </div>
                          <div className="">
                            <input
                              type="checkbox"
                              id="q93Insurance"
                              name="q93Insurance"
                              checked={carTCellDetails.q93Insurance}
                              onChange={HandleFieldChange}
                            />
                            <label htmlFor="q93Insurance">
                              <span className="radio-text-padding">
                                Insurance
                              </span>
                            </label>
                          </div>
                          <div className="">
                            <input
                              type="checkbox"
                              id="q93Travel"
                              name="q93Travel"
                              checked={carTCellDetails.q93Travel}
                              onChange={HandleFieldChange}
                            />
                            <label htmlFor="q93Travel">
                              <span className="radio-text-padding">
                                Need to travel too far to receive infusion
                              </span>
                            </label>
                          </div>
                          <div className="">
                            <input
                              type="checkbox"
                              id="q93Toxicity"
                              name="q93Toxicity"
                              checked={carTCellDetails.q93Toxicity}
                              onChange={HandleFieldChange}
                            />
                            <label htmlFor="q93Toxicity">
                              <span className="radio-text-padding">
                                Toxicity concerns with CAR T cell therapy
                              </span>
                            </label>
                          </div>
                          <div className="">
                            <input
                              type="checkbox"
                              id="q93Preference"
                              name="q93Preference"
                              checked={carTCellDetails.q93Preference}
                              onChange={HandleFieldChange}
                            />
                            <label htmlFor="q93Preference">
                              <span className="radio-text-padding">
                                Patient preference
                              </span>
                            </label>
                          </div>
                          <div className="">
                            <input
                              type="checkbox"
                              id="q93Manufacture"
                              name="q93Manufacture"
                              checked={carTCellDetails.q93Manufacture}
                              onChange={HandleFieldChange}
                            />
                            <label htmlFor="q93Manufacture">
                              <span className="radio-text-padding">
                                Manufacturing issues
                              </span>
                            </label>
                          </div>
                          <div className="">
                            <input
                              type="checkbox"
                              id="q93Other"
                              name="q93Other"
                              checked={carTCellDetails.q93Other}
                              onChange={HandleFieldChange}
                            />
                            <label htmlFor="q93Other">
                              <span className="radio-text-padding">
                                Other, specify:{" "}
                                <input
                                  className="input-dash"
                                  name="q93_other_txt"
                                  type="text"
                                  value={carTCellDetails.q93_other_txt}
                                  maxLength={100}
                                  onChange={HandleFieldChange}
                                />
                              </span>
                            </label>
                          </div>
                          <div className="">
                            <label>
                              <input
                                type="radio"
                                id="q93Unk_99"
                                name="q93Unk"
                                value={99}
                                checked={carTCellDetails.q93Unk === 99}
                                onChange={HandleFieldChange}
                              />
                              <span className="radio-text-padding">
                                Unknown
                              </span>
                            </label>
                          </div>
                        </div>
                        <ErrorField
                          show={hardErrList.q93Empty}
                          message={CarTCellTherapy_Msgs.Q93}
                        />
                        <ErrorField
                          show={softErrList.q93Unk_err}
                          message={CommonMsgs.Unknown}
                        />
                        <ErrorField
                          show={hardErrList.q93EmptyOtherTxt}
                          message={CarTCellTherapy_Msgs.q93EmptyOtherTxt}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="survey-section-content-foot">
                <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CarTCellTherapy;
